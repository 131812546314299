import * as React from "react";

export const AboutMe = () => {
  const prettyPrint = (obj) => {
    return JSON.stringify(obj, null, 2)
      .replace(/\\"/g, "&quot;")
      .replace(
        /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/gm,
        (match, pIndent, pKey, pVal, pEnd) => {
          var key = "<span class=json-key>";
          var val = "<span class=json-value>";
          var str = "<span class=json-string>";
          var r = pIndent || "";
          if (pKey) r = r + key + pKey.replace(/: /g, "") + "</span>: ";
          if (pVal) r = r + (pVal[0] === '"' ? str : val) + pVal + "</span>";
          return r + (pEnd || "");
        }
      );
  };

  const aboutMe = {
    name: "Cory Jbara",
    location: "Chicago, IL",
    education: [
      {
        school: "University of Chicago Booth",
        degree: "MBA",
      },
      {
        school: "University of Notre Dame",
        degree: "B.S. Computer Science",
      },
    ],
    work_history: [
      {
        employer: "Scoop",
        role: "Staff Software Engineer",
        dates: "March 2022 - Present",
      },
      {
        employer: "FanFood",
        role: "Software Architect",
        dates: "May 2021 - March 2022",
      },
      {
        employer: "Arrive - ParkWhiz",
        role: "Software Engineer",
        dates: "February 2018 - April 2021",
      },
      {
        employer: "HERE Technologies",
        role: "Software Engineer",
        dates: "July 2017 - February 2018",
      },
    ],
    hobbies: ["sports", "yoga", "coffee", "plants"],
    hometown: "Detroit, MI",
  };

  return (
    <pre>
      <code dangerouslySetInnerHTML={{ __html: prettyPrint(aboutMe) }} />
    </pre>
  );
};
