import {
  Article as ArticleIcon,
  Email as EmailIcon,
  GitHub as GitHubIcon,
  LinkedIn as LinkedInIcon,
} from "@mui/icons-material";
import {
  Container,
  CssBaseline,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";
import { AboutMe } from "./AboutMe";
import "./App.css";
import image from "./cory.jpeg";

export const App = () => {
  const icons = [
    {
      Icon: EmailIcon,
      link: "mailto:cjbara@gmail.com",
      tooltip: "Email",
    },
    {
      Icon: LinkedInIcon,
      link: "https://www.linkedin.com/in/cory-jbara/",
      tooltip: "LinkedIn",
    },
    {
      Icon: GitHubIcon,
      link: "https://github.com/cjbara",
      tooltip: "GitHub",
    },
    {
      Icon: ArticleIcon,
      link: "/resume.pdf",
      tooltip: "Resume",
    },
  ];

  return (
    <React.Fragment>
      <CssBaseline />
      <Container alignItems="center" maxWidth="sm" component="main">
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <img id="banner-img" alt="Cory Jbara" src={image} />
          </Grid>
        </Grid>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
        >
          Cory Jbara
        </Typography>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Stack alignItems="center" direction="row" spacing={1}>
              {icons.map(({ Icon, link, tooltip }) => (
                <Tooltip title={tooltip} key={tooltip}>
                  <IconButton aria-label={tooltip} href={link} target="_blank">
                    <Icon />
                  </IconButton>
                </Tooltip>
              ))}
            </Stack>
          </Grid>
        </Grid>
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          component="p"
        >
          I am a tech builder, a software architect, a curious mind, a tinkering
          engineer, a fun lover, a food enthusiast, an active body, an energetic
          spirit, and an aspiring pickleball champion.
        </Typography>
        <AboutMe />
      </Container>
    </React.Fragment>
  );
};
